import { useEffect, useState } from 'react';
// import {useMedia} from 'react-use';

const queryStrings = {
  pc: '(min-width: 769px)',
  sp: '(max-width: 768px)',
};


const mq = (mediaList) => {
  const [device, setDevice] = useState('');


  function WidthChange(mediaQuery) {
    setDevice(mediaQuery.matches);
  }

  useEffect(() => {
    if (matchMedia) {
      const mediaQuery = window.matchMedia(queryStrings[mediaList]);
      setDevice(mediaQuery.matches);
      mediaQuery.addListener(WidthChange);
    }
  },[mediaList]);

  return device;
}

export default mq


/*export const useMq = (size) => {
  const isWide = useMedia(`(${size})`);
  return isWide;
}*/

// export default useMediaQuery;
